import React from 'react';

const Header = () => {
  return (
    <div className="header nes-container is-rounded">
      <a href="https://twitter.com/racistio" target="_blank" rel="noopener noreferrer">
        <button>twitter</button>
      </a>

      <a href="https://t.me/racistios" target="_blank" rel="noopener noreferrer">
        <button>telegram</button>
      </a>

    </div>
  );
}

export default Header;
