import React, { useEffect, useRef } from 'react';
import headerImage from './racistiotext.png'; // Ensure the path is correct
import mainImage from './racistio.png'; // Ensure the path is correct
import backgroundMusic from './song.mp3';

const MainContent = () => {
    const audioRef = useRef(null);

    useEffect(() => {
      const playAudio = async () => {
        try {
          await audioRef.current.play();
        } catch (err) {
          console.log('Autoplay failed');
        }
      };
  
      playAudio();
    }, []);

  return (
    <div className="content-container nes-container is-rounded">
      <div className="header-image">
        <img src={headerImage} alt="Header" />
      </div>
      <div className="main-content">
        <img src={mainImage} alt="Main Content" />
      </div>
      <div className="text-content">
        <p>Racistio Brothers is retardio for men.</p><p></p><p>

Racistio Brothers is a collection of 1,488 racist NFT's evolving the proven retardio paradigm with the introduction of young W.H.I.T.E energy, raging 1930s Nazi aesthetics, and digital race terrorism. </p><p>

We engage X in riveting discourse about the state of the world using Yakubian-divulged statistics and reasoning to state our case of the White man being the ultimate apex, the creme de la creme of humanity, the saviours and bringer of peace to the world.</p><p>

The brotherhood is not here to bring peace, but a sword. Pick up your sword and become a member of our family today, we are waiting patiently on your arrival, brother.</p><p>

racistio is not created, racistio is coded</p>
      </div>
      <div className="bottom-buttons">
            <a href="https://discord.com/invite/kTQW5U9PNt" target="_blank" rel="noopener noreferrer">
                <button>discord</button>
            </a>
            <a href="http://tensor.trade" target="_blank" rel="noopener noreferrer">
            <button>tensor</button>
            </a>
            </div>

      <audio autoPlay loop>
        <source src={backgroundMusic} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
    
    </div>
  );
}

export default MainContent;
